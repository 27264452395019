// import functions here
// from swagger-ui/core/util
// so that we can replicate their components
// original: https://github.com/swagger-api/swagger-ui/blob/v5.17.9/src/core/utils/index.js
// original: https://github.com/swagger-api/swagger-ui/blob/v5.17.9/src/core/utils/url.js

import Im from "immutable"
import {sanitizeUrl as braintreeSanitizeUrl} from "@braintree/sanitize-url"
import cssEscape from "css.escape"

/**
 * Returns an Immutable List, safely
 * @param {Immutable.Iterable} iterable the iterable to get the key from
 * @param {String|[String]} key either an array of keys, or a single key
 * @returns {Immutable.List} either iterable.get(keys) or an empty Immutable.List
 */
export function getList(iterable, keys) {
    if(!Im.Iterable.isIterable(iterable)) {
        return Im.List()
    }
    let val = iterable.getIn(Array.isArray(keys) ? keys : [keys])
    return Im.List.isList(val) ? val : Im.List()
}

export const getExtensions = (defObj) => defObj.filter((v, k) => /^x-/.test(k))

export function sanitizeUrl(url) {
    if(typeof url !== "string" || url === "") {
        return ""
    }

    return braintreeSanitizeUrl(url)
}

// suitable for use in URL fragments
export const createDeepLinkPath = (str) => typeof str == "string" || str instanceof String ? str.trim().replace(/\s/g, "%20") : ""
// suitable for use in CSS classes and ids
export const escapeDeepLinkPath = (str) => cssEscape( createDeepLinkPath(str).replace(/%20/g, "_") )

/**
 * Safe version of buildUrl function. `selectedServer` can contain server variables
 * which can fail the URL resolution.
 */
export function safeBuildUrl(url, specUrl, { selectedServer="" } = {}) {
    try {
        return buildUrl(url, specUrl, { selectedServer })
    } catch {
        return undefined
    }
}